<template>
  <en-drawer
    :model-value="modelValue"
    :title="`新建${type === 'warehose' ? '仓库' : '网点'}补货单`"
    @close="$emit('update:model-value', false)"
    direction="btt"
    size="80%"
  >
    <en-form label-position="left" class="grid grid-cols-4 gap-x-6">
      <en-form-item v-if="type === 'tenant'" label="补货网点">
        <cascader-maintain
          v-model="form.data.tenantIds"
          :ajax="{ action: 'GET /enospray/common/tenant/all', params: (params) => (params.payload = { salesmanId: form.data.salesmanId }) }"
          :props="[
            { label: 'message', value: 'code' },
            { label: 'shortName', value: 'id' }
          ]"
          @change="form.tenantIds.change"
          class="w-full"
        ></cascader-maintain>
      </en-form-item>
      <en-form-item v-if="type === 'warehose'" label="补货仓库">
        <select-maintain
          v-model="form.data.storageWarehouseId"
          :ajax="{ action: 'GET /enospray/common/warehouse', params: (params, name) => (params.payload = { name }) }"
          :props="{ label: 'name', value: 'id' }"
          class="w-full"
          @change="form.storageWarehouseId.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="日期范围">
        <en-date-picker
          v-model:start="form.data.startDate"
          v-model:end="form.data.endDate"
          type="daterange"
          class="w-full"
          @change="form.date.change"
        ></en-date-picker>
      </en-form-item>
      <en-form-item v-if="type === 'tenant'" label="业务员">
        <select-maintain
          v-model="form.data.salesmanId"
          :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
          :props="{ label: 'plateNo', value: '' }"
          value-key="id"
          remote
          class="w-full"
          @change="form.salesmanId.change"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <div v-if="type === 'tenant'" v-loading="workSummary.loading" class="p-4 flex items-center justify-around gap-6">
      <div>
        <span>钣面数：</span>
        <span>{{ formatNumber(workSummary.data.varnishInventoryAmount, 2) }}</span>
      </div>
      <div>
        <span>工单数：</span>
        <span>{{ formatNumber(workSummary.data.normalOrderCount, 2) }}</span>
      </div>
      <div>
        <span>产值：</span>
        <span>{{ formatNumber(workSummary.data.normalSurfaceAmount, 2) }}</span>
      </div>
      <div>
        <span>库存金额：</span>
        <span>{{
          formatNumber(
            calculator.add(
              calculator.add(
                calculator.add(workSummary.data.colorInventoryAmount, workSummary.data.deviceInventoryAmount),
                workSummary.data.materialInventoryAmount
              ),
              workSummary.data.varnishInventoryAmount
            ),
            2
          )
        }}</span>
      </div>
      <div>
        <span>补货钣面数：</span>
        <en-input-number v-model="workSummary.data.predictSurfaceCount" :min="0" @change="workSummary.predictSurfaceCount.change"></en-input-number>
      </div>
      <div>
        <span>距离上次补货时间：</span>
        <span>{{ workSummary.data.lastReplenishDateDifference }}天</span>
      </div>
    </div>

    <div v-if="type === 'warehose'" v-loading="warehouseSummary.loading" class="p-4 flex items-center justify-around gap-6">
      <div>
        <span>消耗色母金额：</span>
        <span>{{ formatNumber(warehouseSummary.data.colorConsumeAmount, 2) }}</span>
      </div>
      <div>
        <span>消耗清漆金额：</span>
        <span>{{ formatNumber(warehouseSummary.data.varnishConsumeAmount, 2) }}</span>
      </div>
      <div>
        <span>消耗辅料金额：</span>
        <span>{{ formatNumber(warehouseSummary.data.materialConsumeAmount, 2) }}</span>
      </div>
      <div>
        <span>消耗设备金额：</span>
        <span>{{ formatNumber(warehouseSummary.data.deviceConsumeAmount, 2) }}</span>
      </div>
      <div>
        <span>库存金额：</span>
        <span>{{ formatNumber(warehouseSummary.inventoryAmount, 2) }}</span>
      </div>
      <div>
        <span>消耗设备金额：</span>
        <span>{{ formatNumber(warehouseSummary.data.deviceConsumeAmount, 2) }}</span>
      </div>
      <div>
        <span>补货天数：</span>
        <!-- <en-input-number v-model="warehouseSummary.duration.value" :min="0" @change="warehouseSummary.duration.change"></en-input-number> -->
      </div>
    </div>

    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="tabs.active">
          <en-tab-pane v-for="item of goodsType.data" :label="item.message" :name="item.code"> </en-tab-pane>
        </en-tabs>

        <en-table :data="table.typeData(tabs.active)" :height="height - 55" :loading="table.loading" @selection-change="table.selection.change">
          <en-table-column type="selection"></en-table-column>
          <en-table-column label="配件编号" prop="goods.storageGoodsSpecification.goods.serialNo"></en-table-column>
          <en-table-column label="配件名称" prop="goods.storageGoodsSpecification.goods.name"></en-table-column>
          <en-table-column label="规格" prop="goods.storageGoodsSpecification.name"></en-table-column>
          <en-table-column label="每钣面用量" prop="singleUsage" sortable>
            <template #default="{ row }: { row: GoodsConsume }">
              {{ formatNumber(row.singleUsage, 2) }}{{ row.goods?.workshopGoodsSpecification?.name }}
            </template>
          </en-table-column>
          <en-table-column label="总用量" prop="usedCount" sortable>
            <template #default="{ row }: { row: GoodsConsume }">
              {{ formatNumber(row.usedCount, 2) }}{{ row.goods?.storageGoodsSpecification?.name }}
            </template>
          </en-table-column>
          <en-table-column label="总库存" prop="totalInventory" sortable>
            <template #default="{ row }: { row: GoodsConsume }">
              {{ formatNumber(row.totalInventory) }}{{ row.goods?.storageGoodsSpecification?.name }}
            </template>
          </en-table-column>
          <en-table-column label="仓库库存" prop="storageInventory" sortable>
            <template #default="{ row }: { row: GoodsConsume }">{{ formatNumber(row.storageInventory) }}</template>
          </en-table-column>
          <en-table-column label="调漆房库存" prop="workshopInventory" sortable>
            <template #default="{ row }: { row: GoodsConsume }">{{ formatNumber(row.workshopInventory) }}</template>
          </en-table-column>
          <en-table-column label="采购预进" prop="finalAwaitingStockInCount" sortable>
            <template #default="{ row }: { row: GoodsConsume }">
              {{ formatNumber(row.finalAwaitingStockInCount, 2) }}{{ row.goods?.storageGoodsSpecification?.name }}
            </template>
          </en-table-column>
          <en-table-column label="调入在途" prop="finalAwaitingTransferInCount" sortable>
            <template #default="{ row }: { row: GoodsConsume }">
              {{ formatNumber(row.finalAwaitingTransferInCount, 2) }}{{ row.goods?.storageGoodsSpecification?.name }}
            </template>
          </en-table-column>
          <en-table-column label="最低库存量" sortable>
            <template #default="{ row }: { row: GoodsConsume }">{{ formatNumber(row.goods?.lowerLimit) }}</template>
          </en-table-column>
          <en-table-column label="预计使用量" prop="predictUsage" sortable>
            <template #default="{ row }: { row: GoodsConsume }">{{ formatNumber(row.predictUsage, 2) }}</template>
          </en-table-column>
          <en-table-column label="理论补货量" prop="count" sortable width="150">
            <template #default="{ row }: { row: GoodsConsume }">{{ formatNumber(row.count, 2) }}</template>
          </en-table-column>
          <en-table-column label="最终补货量" prop="finalCount" sortable width="150">
            <template #default="{ row }: { row: GoodsConsume }">
              <en-input-number
                v-model="row.finalCount"
                step-strictly
                :step="row.goods?.replenishCount ? row.goods?.replenishCount : 1"
                :min="0"
                @change="table.finalCount.change(row)"
                class="w-full"
              ></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="补货金额" prop="replenishmentValue" sortable>
            <template #default="{ row }: { row: GoodsConsume }">{{ formatMoney(row.replenishmentValue, 2) }}</template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>

  <en-dialog v-model="dialog.visible" title="全部配件">
    <en-form inline label-position="left">
      <en-form-item label="备注">
        <en-input v-model="dialog.form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <en-table :data="dialog.form.data.replenishmentGoods" :loading="dialog.form.loading">
      <en-table-column label="操作">
        <template #default="{ $index }: { $index: number }">
          <en-button type="primary" link @click="dialog.form.replenishmentGoods.operation.delete.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="配件编号" prop="goods.storageGoodsSpecification.goods.serialNo" width="200"></en-table-column>
      <en-table-column label="配件名称" prop="goods.storageGoodsSpecification.goods.name" width="120"></en-table-column>
      <en-table-column label="补货数量" width="240">
        <template #default="{ row }: { row: ReplenishmentGoodsDto }">
          <en-input-number
            v-model="row.finalCount"
            step-strictly
            :step="row.goods?.replenishCount ? row.goods?.replenishCount : 1"
            :min="0"
            @change="dialog.form.replenishmentGoods.finalCount.change(row)"
            class="w-full"
          ></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="单位" prop="goods.storageGoodsSpecification.name"></en-table-column>
      <en-table-column label="补货金额（元）" prop="replenishmentValue">
        <template #default="{ row }: { row: ReplenishmentGoodsDto }">
          {{ formatMoney(row.replenishmentValue) }}
        </template>
      </en-table-column>
      <en-table-column label="备注">
        <template #default="{ row }: { row: ReplenishmentGoodsDto }">
          <en-input v-model="row.comment"></en-input>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="dialog.footer.cancel.click">取消</en-button>
      <button-ajax :method="dialog.footer.confirm.click">确定</button-ajax>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { EnMessage } from '@enocloud/components'
import { calculator } from '@enocloud/utils'
import dayjs from 'dayjs'

interface GoodsConsume {
  /** 采购预进 */
  awaitingStockInCount?: number
  /** 销售预出 */
  awaitingStockOutCount?: number
  /** 移库预进 */
  awaitingTransferInCount?: number
  /** 移出在途 */
  awaitingTransferOutCount?: number
  goods?: EnosprayReplenishmentDefinitions['SprayGoodsDto']
  goodsAmount?: number
  goodsUsage?: number
  storageInventory?: number
  surplusTransferOutCount?: number
  totalInventory?: number
  workshopInventory?: number

  // append prop
  singleUsage: number
  usedCount: number
  finalAwaitingStockInCount: number
  finalAwaitingTransferInCount: number
  predictUsage: number
  count: number
  finalCount: number
  replenishmentValue: number
  initLowerLimit: number
}

type SprayGoodsReplenishmentTypeDto = EnosprayReplenishmentDefinitions['SprayGoodsReplenishmentTypeDto'] & {
  goodsConsumes: GoodsConsume[]
}

type ReplenishmentGoodsDto = EnosprayReplenishmentDefinitions['ReplenishmentGoodsDto'] & {
  replenishmentValue: number
}

const convertData = (
  data: GoodsConsume[],
  summary: EnosprayReplenishmentDefinitions['WorkOrderSummaryDto'],
  init: boolean = false
): GoodsConsume[] => {
  return data.map((item) => {
    const singleUsage = calculator.div(item.goodsUsage, summary.normalSurfaceCount)

    const predictUsage = calculator.mul(
      calculator.div(summary.predictSurfaceCount, summary.normalSurfaceCount),
      item.goods?.workshopGoodsSpecification?.weight
        ? calculator.div(calculator.mul(item.goodsUsage, item.goods.workshopGoodsSpecification?.weight), item.goods.storageGoodsSpecification.weight)
        : calculator.div(item.goodsUsage, item.goods?.storageGoodsSpecification.weight)
    )

    const finalAwaitingTransferInCount = item.goods?.workshopGoodsSpecification?.weight
      ? calculator.div(
          calculator.mul(item.awaitingTransferInCount, item.goods.workshopGoodsSpecification?.weight),
          item.goods.storageGoodsSpecification.weight
        )
      : calculator.div(item.awaitingTransferInCount, item.goods?.storageGoodsSpecification.weight)

    const finalAwaitingStockInCount = item.goods?.workshopGoodsSpecification?.weight
      ? calculator.div(
          calculator.mul(item.awaitingStockInCount, item.goods.workshopGoodsSpecification?.weight),
          item.goods.storageGoodsSpecification.weight
        )
      : calculator.div(item.awaitingStockInCount, item.goods?.storageGoodsSpecification.weight)

    let count = 0
    let differenceCount = calculator.add(
      calculator.add(calculator.sub(item.totalInventory, predictUsage), finalAwaitingTransferInCount),
      finalAwaitingStockInCount
    )

    // 总库存+采购预进+调入在途-最低库存量
    let minDifferenceCount = calculator.add(
      calculator.add(calculator.sub(item.totalInventory, item.goods?.lowerLimit), finalAwaitingTransferInCount),
      finalAwaitingStockInCount
    )

    // 总库存+采购预进+调入在途-预计使用量
    if (differenceCount < 0 && minDifferenceCount < 0) {
      count = calculator.mul(
        Math.ceil(calculator.div(Math.max(Math.abs(differenceCount), Math.abs(minDifferenceCount)), item.goods?.replenishCount)),
        item.goods?.replenishCount
      )
    }
    if (differenceCount < 0 && minDifferenceCount >= 0) {
      count = calculator.mul(Math.ceil(calculator.div(Math.abs(differenceCount), item.goods?.replenishCount)), item.goods?.replenishCount)
    }
    if (differenceCount >= 0 && minDifferenceCount < 0) {
      count = calculator.mul(Math.ceil(calculator.div(Math.abs(minDifferenceCount), item.goods?.replenishCount)), item.goods?.replenishCount)
    }

    const usedCount = item.goods?.workshopGoodsSpecification?.weight
      ? calculator.div(calculator.mul(item.goodsUsage, item.goods.workshopGoodsSpecification?.weight), item.goods.storageGoodsSpecification.weight)
      : calculator.div(item.goodsUsage, item.goods?.storageGoodsSpecification.weight)

    const finalCount = count > 0 ? count : 0

    const replenishmentValue = calculator.mul(item.goods?.primeCost, Math.ceil(count > 0 ? count : 0))

    if (init) item.initLowerLimit = item.goods?.lowerLimit ?? 0

    if (!init && item.goods) {
      item.goods.lowerLimit = item.initLowerLimit
      const exist = (item.goods.lowerInventories as any[]).find(
        (li) => summary.predictSurfaceCount >= li.lowerLimit && summary.predictSurfaceCount <= li.upperLimit
      )
      if (exist) item.goods.lowerLimit = exist.count
    }

    return {
      ...item,
      singleUsage,
      usedCount,
      finalAwaitingStockInCount,
      finalAwaitingTransferInCount,
      predictUsage,
      count,
      finalCount,
      replenishmentValue
    }
  })
}

export default factory({
  props: {
    modelValue: Boolean as PropType<boolean>,
    data: Object as PropType<EnosprayReplenishmentDefinitions['ReplenishmentDto']>,
    type: String as PropType<string>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  config: {
    children: {
      form: {
        data: {
          tenantIds: [],
          startDate: dayjs().subtract(2, 'months').add(1, 'days').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
          salesmanId: '',
          storageWarehouseId: ''
        },
        async submit() {
          const { tenantIds, startDate, endDate, storageWarehouseId } = this.form.data
          if ((this.type === 'tenant' && !tenantIds) || !tenantIds.length) return EnMessage.warning('请选择补货网点')
          if (this.type === 'warehose' && !storageWarehouseId) return EnMessage.warning('请选择补货仓库')
          if (!startDate || !endDate) return EnMessage.warning('请选择时间范围')
          await this[this.type === 'tenant' ? 'workSummary' : 'warehouseSummary'].get()
          await this.table.get()
        },
        children: {
          tenantIds: {
            change() {
              this.form.submit()
            }
          },
          storageWarehouseId: {
            change() {
              this.form.submit()
            }
          },
          date: {
            change() {
              this.form.submit()
            }
          },
          salesmanId: {
            change() {
              this.form.submit()
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      goodsType: {
        ajax: {
          get: {
            action: 'GET /enospray/common/lookup/:lookupType',
            data: 'array',
            params(params) {
              params.paths = ['SPRGDSTPRNTTY']
              params.payload = { excludes: ['NA'] }
            }
          }
        }
      },
      workSummary: {
        ajax: {
          get: {
            action: 'GET /enospray/replenishment/work/summary',
            data: 'object',
            loading: true,
            params(params) {
              params.payload = {
                startDate: this.form.data.startDate,
                endDate: this.form.data.endDate,
                tenantId: [...this.form.data.tenantIds].pop()
              }
            }
          }
        },
        children: {
          predictSurfaceCount: {
            change() {
              for (const item of this.table.data) {
                item.goodsConsumes = convertData(item.goodsConsumes, this.workSummary.data, true)
              }
            }
          }
        }
      },
      warehouseSummary: {
        ajax: {
          get: {
            action: 'GET /enospray/replenishment/warehouse/summary',
            data: 'object',
            loading: true,
            params(params) {
              params.payload = {
                startDate: this.form.data.startDate,
                endDate: this.form.data.endDate,
                storageWarehouseId: this.form.data.storageWarehouseId
              }
            }
          }
        },
        computed: {
          inventoryAmount() {
            return calculator.add()
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/replenishment/goods/summary',
            data: 'array',
            loading: true,
            params(params) {
              params.payload = {
                startDate: this.form.data.startDate,
                endDate: this.form.data.endDate,
                tenantId: [...this.form.data.tenantIds].pop()
              }
            },
            convert(data: SprayGoodsReplenishmentTypeDto[]): SprayGoodsReplenishmentTypeDto[] {
              return data.map((item) => {
                item.goodsConsumes = convertData(item.goodsConsumes, this.workSummary.data, true)
                return item
              })
            }
          }
        },
        typeData(code: string) {
          const data = (this.table.data.find((item) => item.code === code) as SprayGoodsReplenishmentTypeDto | undefined)?.goodsConsumes ?? []
          return data.map((item) => {
            return {
              ...item,
              singleUsage: calculator.div(item.goodsUsage, this.workSummary.data.normalSurfaceCount)
            }
          })
        },
        children: {
          selection: {
            data: [] as GoodsConsume[],
            change(rows: GoodsConsume[]) {
              this.table.selection.data = rows
            }
          },
          finalCount: {
            change(row: GoodsConsume) {
              row.replenishmentValue = calculator.mul(row.goods?.primeCost, row.finalCount)
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          click() {
            this.dialog.form.data.comment = ''
            this.dialog.form.data.replenishmentGoods = this.table.selection.data.map((item) => {
              return {
                finalCount: item.finalCount,
                goods: item.goods,
                replenishmentValue: item.replenishmentValue
              }
            })
            this.dialog.visible = true
          }
        }
      },
      dialog: {
        visible: false,
        children: {
          form: {
            data: {
              comment: '',
              replenishmentGoods: [] as ReplenishmentGoodsDto[]
            },
            ajax: {
              submit: {
                action: 'POST /enospray/replenishment',
                loading: true,
                params(params) {
                  params.payload = this.dialog.form.data
                  params.payload.dateFrom = this.form.data.startDate
                  params.payload.dateEnd = this.form.data.endDate
                  params.payload.tenantId = [...this.form.data.tenantIds].pop()
                }
              }
            },
            children: {
              replenishmentGoods: {
                operation: {
                  delete: {
                    click(index: number) {
                      this.dialog.form.data.replenishmentGoods.splice(index, 1)
                    }
                  }
                },
                finalCount: {
                  change(row: ReplenishmentGoodsDto) {
                    row.replenishmentValue = calculator.mul(row.goods?.primeCost, row.finalCount)
                  }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {}
            },
            confirm: {
              async click() {
                const res = await this.dialog.form.submit()
                this.dialog.visible = false
                this.emit('update:model-value', false)
                this.emit('confirm')
              }
            }
          }
        }
      }
    }
  },

  async mounted() {
    await this.goodsType.get()
    this.tabs.active = this.goodsType.data[0]?.code ?? ''
  }
})
</script>
